<template>
  <div>
    <div>
      <div class="culture-container">
        <div class="culture-content">
          <div class="tab-secondNav" v-if="secondNav.length > 0">
            <ul class="tab-secondNav-choose-item">
              <li
                v-for="(item, i) in secondNav"
                :key="i"
                :class="{ tabActivesecondNav: cur2 == item.id }"
                @click="changeSecondNav(item, i)"
              >
                {{ item.name }}
                <span :class="{ tabActivesecondNavSpan: cur2 == item.id }">
                  <span :class="{ tabActivesecondNavSubSpan: cur2 == item.id }">
                  </span>
                </span>
              </li>
            </ul>
          </div>
          <div class="culture-switch-type" v-if="categoryList&&categoryList.length>0">
            <div class="culture-switch-type-title"></div>
            <div class="culture-switch-type-content">
              <div
                class="culture-switch-type-content-li"
                :class="{
                  'culture-switch-type-content-li-active': cur == item.id,
                  imgType: item.label_name.length > 4,
                }"
                v-for="(item, index) in categoryList"
                :key="'categoryList' + index"
                @click="handleChangeValue(item.id)"
              >
                {{ item.label_name }}
              </div>
            </div>
          </div>
          <div class="culture-switch-type">
            <div class="culture-switch-type-title"></div>
            <div class="culture-switch-type-content">
              <div
                class="culture-switch-type-content-li"
                :class="{
                  'culture-switch-type-content-li-active': cur3 == item.id,
                  imgType: item.name.length > 4,
                }"
                v-for="(item, index) in typeList"
                :key="item.id"
                @click="handleChangeStatus(item.id)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>

          <!-- 列表 -->
          <div class="culture-info" v-if="rows.length > 0">
            <div>
              <ul class="culture-ul">
                <li
                  class="culture-li"
                  v-for="(item, index) in rows"
                  :key="'culture' + index"
                >
                  <div @click="toNewPage(item)" target="_blank">
                    <div class="culture-li-banner">
                      <img
                        style="width: 100%; height: 100%"
                        :src="item.cover"
                        alt=""
                      />
                      <div class="courselistState" :class="`coursecurrent${item.course_status}`">
                <!-- 0未开课，1已开课，2已结课 -->
                <div class="courselistStateFont" v-if="item.course_status == 0">待开始</div>
                <div class="courselistStateFont" v-else-if="item.course_status == 1">进行中</div>
                <div class="courselistStateFont" v-else-if="item.course_status == 2">已结束</div>
              </div>
                    </div>
                    <div class="culture-li-bottom">
                      <div class="culture-li-bottom-title">
                        {{ item.title }}
                      </div>
                      <div class="culture-li-bottom-des">
                        <img
                          class="culture-li-bottom-icon"
                          src="https://image.bookgo.com.cn/webculture/jm/detail/detail_culture_speaker.png"
                          alt=""
                        />
                        <div class="culture-li-bottom-icon-title">
                          授课老师：{{ item.speaker }}
                        </div>
                      </div>
                      <div class="culture-li-bottom-des">
                        <img
                          class="culture-li-bottom-icon"
                          src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_activity_date_icon.png"
                          alt=""
                        />
                        <div class="culture-li-bottom-icon-title">
                          上课时间：{{ item.start_date }} ~
                          {{ item.end_date }}
                        </div>
                      </div>
                      <div class="culture-li-bottom-des">
                        <img
                          class="culture-li-bottom-icon"
                          src="https://image.bookgo.com.cn/webculture/jm/detail/detail_culture_chapter.png"
                          alt=""
                        />
                        <div class="culture-li-bottom-icon-title">
                          课时：{{ item.chapter_count }}节
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    class="culture-li-selected"
                    src="https://image.bookgo.com.cn/webculture/jm/homepage/homepage_selected_li.png"
                    alt=""
                  />
                </li>
              </ul>
            </div>
          </div>

          <!-- 分页组件 -->
          <pagination
            :total="count"
            :page.sync="page"
            :limit.sync="limit"
            @pagination="getList"
            v-if="rows.length > 0"
          />
          <Nocontent v-else title="暂无艺术培训，请持续关注哦～"></Nocontent>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { courseList } from "@/api/culture/culture";
import Pagination from "@/components/Pagination";
import Nocontent from "@/views/components/Nocontent";
import { navChildList } from "@/api/header/header";

export default {
  data() {
    return {
      count: 0,
      page: 1,
      limit: 9,
      rows: [],
      aid: process.env.VUE_APP_AID,
      current: 0,
      id: "",
      cat_id: "",
      //loading: true
      secondNav: [],
      cur2: 0,
      cur: 0,
      cur3: -1,
      column_id: "",
      cid: "",
      categoryList: [],
      typeList: [
        { id: -1, name: "全部" },
        { id: 0, name: "待开始" },
        { id: 1, name: "进行中" },
        { id: 2, name: "已结束" },
      ],
    };
  },
  components: {
    Pagination,
    Nocontent,
  },
  watch: {
    $route(newV) {
      window.scroll(0, 0);
      this.page = 1;
      this.cur = 0;
      this.cur3 = -1;
      this.column_id = newV.query.column_id;
      this.cid = newV.query.cid;
      if (this.cid) {
        this.cur2 = this.cid;
      }
      this.secondNav = [];
      this.categoryList = [];
      this.getList();
      this.getChildNav();
    },
  },
  mounted() {
    window.scroll(0, 0);
    //默认加载第一页
    this.column_id = this.$route.query.column_id;
    this.cid = this.$route.query.cid;
    if (this.cid) {
      this.cur2 = this.cid;
    }
    this.getList();
    this.getChildNav();
  },
  methods: {
    changeSecondNav(item) {
      //获取导航下的分类
      if (item.url_type == 1 && item.url) {
        window.open(item.url, "_blank");
      } else {
        if (item.content_id == 0) {
          this.$router.push(
                `/${item.tpl_code}?id=${this.$route.query.id}&cid=${item.id}&column_id=${item.column_id}`
              );
          // let routeUrl = this.$router.resolve({
          //   path: `/${item.tpl_code}`,
          //   query: {
          //     id: this.$route.query.id,
          //     cid: item.id,
          //     column_id: item.column_id,
          //   },
          // });
          // window.open(routeUrl.href, "_blank");
        } else {
          this.$router.push(
                `/${item.tpl_code}/detail?tid=${item.content_id}&id=${this.$route.query.id}&cid=${item.id}&column_id=${item.column_id}`
              );
          // let routeUrl = this.$router.resolve({
          //   path: `/${item.tpl_code}/detail`,
          //   query: {
          //     tid: item.content_id,
          //     id: this.$route.query.id,
          //     cid: item.id,
          //     column_id: item.column_id,
          //   },
          // });
          // window.open(routeUrl.href, "_blank");
        }
      }
    },
    getChildNav() {
      let params = {
        aid: this.aid,
        pid: this.$route.query.id,
        app_type: 1,
      };
      navChildList(params).then((res) => {
        if (res.code == 0) {
          this.secondNav = res.data;
        }
      });
    },
    handleChangeValue(i) {
      this.page = 1;
      this.cur = i;
      this.getList();
    },
    handleChangeStatus(i){
      this.page = 1;
      this.cur3 = i;
      this.getList();
    },
    getList() {
      let params = {
        aid: this.aid,
        page: this.page,
        page_size: this.limit,
        column_id: this.column_id,
      };
      if (parseInt(this.cur) > 0) {
        params.label_id = parseInt(this.cur);
      }
      if(parseInt(this.cur3)>=0){
        params.course_status = parseInt(this.cur3);
      }
      courseList(params).then((res) => {
        if (res.code == 0) {
          this.rows = res.data.datalist;
          this.count = res.data.total;
          if (res.data.label_list.length > 0) {
            this.categoryList = res.data.label_list;
            this.categoryList.unshift({
              id: 0,
              label_name: "全部",
            });
          }
        }
      });
    },
    toNewPage(item) {
      if (item.is_link == 1 && item.jump_url) {
        window.open(item.jump_url, "_blank");

      } else {
        let routeUrl = this.$router.resolve({
          path: `/course-default/detail`,
          query: { tid:item.id,id:this.$route.query.id,cid:this.$route.query.cid,column_id:this.$route.query.column_id },
        });
        window.open(routeUrl.href, "_blank");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/course_default_index.scss";
.tab-secondNav-choose-item {
  font-size: 0.17rem;
  border-bottom: 1px solid #d5d5d5;
  padding-bottom: 0.14rem;
}
.tab-secondNav-choose-item {
  display: flex;
}
.tab-secondNav-choose-item li {
  margin-left: 10px;
}
.tab-secondNav-choose-item li {
  cursor: pointer;
}
.tab-secondNav-choose-item li:not(:first-child) {
  cursor: pointer;
  margin-left: 0.46rem;
}
.tabActivesecondNav {
  color: #761f1e;
  position: relative;
}

.tabActivesecondNavSpan {
  width: 100%;
  height: 3px;
  display: inline-block;
  position: absolute;
  bottom: -0.14rem;
  left: 0rem;
}
.tabActivesecondNavSubSpan {
  width: 80%;
  background: #991c10;
  height: 3px;
  display: inline-block;
  position: absolute;
  animation: 0px;
  margin: auto;
  left: 0px;
  right: 0px;
}
.tab-subSwitch {
  margin-top: 0.33rem;
}
.news-container {
  padding-top: 0.38rem;
}
</style>
